import React, { useState } from "react";
import { useEffect } from "react";

function Alerts({ alertType, children }) {
    const [alertShow, setAlertShow] = useState(children ? children : null);
    const closeHandler = () => {
        setAlertShow(false);
    };

    useEffect(() => {
        if (children) setAlertShow(true);
    }, [children]);

    return (
        alertShow && (
            <div>
                {alertType === "Info" && (
                    <div
                        id="alert-1"
                        className="mb-4 flex rounded-lg bg-blue-100 p-4 dark:bg-blue-200"
                        role="alert"
                    >
                        <svg
                            className="h-5 w-5 flex-shrink-0 text-blue-700 dark:text-blue-800"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <div className="ml-3 text-sm font-medium text-blue-700 dark:text-blue-800">
                            {children}
                        </div>
                        <button
                            onClick={() => closeHandler()}
                            type="button"
                            className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-blue-100 p-1.5 text-blue-500 hover:bg-blue-200 focus:ring-2 focus:ring-blue-400 dark:bg-blue-200 dark:text-blue-600 dark:hover:bg-blue-300"
                            data-dismiss-target="#alert-1"
                            aria-label="Close"
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
                {alertType === "Error" && (
                    <div
                        id="alert-2"
                        className="mb-4 flex rounded-lg bg-red-100 p-4 dark:bg-red-200"
                        role="alert"
                    >
                        <svg
                            className="h-5 w-5 flex-shrink-0 text-red-700 dark:text-red-800"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                            {children}
                        </div>
                        <button
                            onClick={() => closeHandler()}
                            type="button"
                            className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-red-100 p-1.5 text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-red-400 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300"
                            data-dismiss-target="#alert-2"
                            aria-label="Close"
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
                {alertType === "Success" && (
                    <div
                        id="alert-3"
                        className="mb-4 flex rounded-lg bg-green-100 p-4 dark:bg-green-200"
                        role="alert"
                    >
                        <svg
                            className="h-5 w-5 flex-shrink-0 text-green-700 dark:text-green-800"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                            {children}
                        </div>
                        <button
                            onClick={() => closeHandler()}
                            type="button"
                            className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-green-100 p-1.5 text-green-500 hover:bg-green-200 focus:ring-2 focus:ring-green-400 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"
                            data-dismiss-target="#alert-3"
                            aria-label="Close"
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
                {alertType === "hint" && (
                    <div
                        id="alert-4"
                        className="mb-4 flex rounded-lg bg-yellow-100 p-4 dark:bg-yellow-200"
                        role="alert"
                    >
                        <svg
                            className="h-5 w-5 flex-shrink-0 text-yellow-700 dark:text-yellow-800"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <div className="ml-3 text-sm font-medium text-yellow-700 dark:text-yellow-800">
                            {children}
                        </div>
                        <button
                            onClick={() => closeHandler()}
                            type="button"
                            className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-yellow-100 p-1.5 text-yellow-500 hover:bg-yellow-200 focus:ring-2 focus:ring-yellow-400 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300"
                            data-dismiss-target="#alert-4"
                            aria-label="Close"
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
                {alertType === "" && (
                    <div
                        id="alert-5"
                        className="flex rounded-lg bg-gray-100 p-4 dark:bg-gray-700"
                        role="alert"
                    >
                        <svg
                            className="h-5 w-5 flex-shrink-0 text-gray-700 dark:text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <div className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
                            A simple dark alert with an{" "}
                            <a
                                href="#"
                                className="font-semibold underline hover:text-gray-800 dark:hover:text-white"
                            >
                                example link
                            </a>
                            . Give it a click if you like.
                        </div>
                        <button
                            onClick={() => closeHandler()}
                            type="button"
                            className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-gray-100 p-1.5 text-gray-500 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-white"
                            data-dismiss-target="#alert-5"
                            aria-label="Close"
                        >
                            <span className="sr-only">Dismiss</span>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        )
    );
}

export default Alerts;
