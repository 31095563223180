const FilterText = (str) => {
  if (typeof str === "string") {
    let txt = str
      .toString()
      .replaceAll(/Firebase:|Error|Auth/gi, "")
      .replace(/[^a-zA-Z ]/gi, " ")
      .trim();
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }

  return;
};

export default FilterText;
