import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useReducer } from "react";
import { auth } from "../firebase";

const INITIAL_STATE = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
};

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      if (action.payload) {
        localStorage.setItem(
          "user",
          action.payload ? JSON.stringify({ ...action.payload }) : null
        );
        return { ...state, user: action.payload, isAuthReady: true };
      } else {
        localStorage.removeItem("user");
        return { ...state, user: action.payload, isAuthReady: false };
      }
    case "LOGOUT":
      return { ...state, user: null, isAuthReady: false };
    case "AUTH_IS_READY": {
      if (action.payload) {
        localStorage.setItem(
          "user",
          action.payload ? JSON.stringify({ ...action.payload }) : null
        );
        return { ...state, user: action.payload, isAuthReady: true };
      } else {
        localStorage.removeItem("user");
        return { ...state, user: action.payload, isAuthReady: false };
      }
    }
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const initialValue = {
    user: INITIAL_STATE,
    isAuthReady: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialValue);

  useEffect(() => {
    let subscribe = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
    });

    return () => subscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
